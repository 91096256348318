var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',[_vm._v(" Referans Öğrenciler ")]),_c('CCardBody',[_c('CInput',{attrs:{"label":"Arama: ","placeholder":"Aramak istediğiniz öğrenciyi yazınız...","horizontal":{ label: 'w-auto', input: 'w-100' }},model:{value:(_vm.filterValue),callback:function ($$v) {_vm.filterValue=$$v},expression:"filterValue"}}),_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","loading":_vm.isDataLoading,"items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('CRow',[_c('CCol',{staticClass:"col-12 font-3xl text-center"},[_c('CIcon',{attrs:{"name":"cil-x"}}),_vm._v(" Veri Bulunamadı ")],1)],1)]},proxy:true},{key:"id",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(_vm._s(item.id))]),_c('div',{staticClass:"small text-muted"})])}},{key:"avatarUrl_1X",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('img',{staticClass:"c-avatar-img",staticStyle:{"width":"64px","height":"64px"},attrs:{"src":item.avatar1X ? item.avatar1X : ' '},on:{"error":_vm.fallback}})])}},{key:"name",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',{staticClass:"clearfix"}),_c('div',[_vm._v(_vm._s(item.name))])])}},{key:"registeredAt",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(item.registeredAt)))])])}},{key:"eMail",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',[_vm._v(_vm._s(item.eMail))])])}}])}),(_vm.totalPages !== 0)?_c('CPagination',{staticClass:"mt-3",attrs:{"pages":_vm.totalPages,"active-page":_vm.currentPage,"activePage":_vm.currentPage,"align":"center"},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }