<template>
    <CCard>
        <CCardHeader>
            Referans Öğrenciler
        </CCardHeader>
        <CCardBody>
            <CInput label='Arama: ' placeholder='Aramak istediğiniz öğrenciyi yazınız...'
                :horizontal="{ label: 'w-auto', input: 'w-100' }" v-model='filterValue' />
            <CDataTable class='mb-0 table-outline' hover :items='tableItems' :fields='tableFields' head-color='light'
                :loading='isDataLoading' :items-per-page='itemsPerPage'>
                <template #no-items-view>
                    <CRow>
                        <CCol class='col-12 font-3xl text-center'>
                            <CIcon name='cil-x' />
                            Veri Bulunamadı
                        </CCol>
                    </CRow>
                </template>
                <td slot='id' slot-scope='{item}'>
                    <div>{{ item.id }}</div>
                    <div class='small text-muted'>
                    </div>
                </td>
                <td slot='avatarUrl_1X' class='text-center' slot-scope='{item}'>
                    <img class='c-avatar-img' style='width: 64px; height: 64px' @error='fallback'
                        :src="item.avatar1X ? item.avatar1X : ' '" />
                </td>
                <td slot='name' slot-scope='{item}'>
                    <div class='clearfix'>
                    </div>
                    <div>{{ item.name }}</div>
                </td>
                <td slot='registeredAt' slot-scope='{item}' class='text-center'>
                    <div>{{ item.registeredAt | formatDate }}</div>
                </td>
                <td slot='eMail' slot-scope='{item}' class='text-center'>
                    <div>{{ item.eMail }}</div>
                </td>
            </CDataTable>
            <CPagination v-if='totalPages !== 0' :pages='totalPages' :active-page='currentPage'
                :activePage.sync='currentPage' align='center' class='mt-3' />
        </CCardBody>
    </CCard>
</template>

<script>
import { ReferralAdministration } from '@/services/api-service'

export default {
    name: 'ManageReferral',
    components: {},
    mounted() {
        this.loadStudents()
    },
    watch: {
        filterValue() {
            this.loadStudents()
        },
        currentPage() {
            this.loadStudents()
        },
    },
    data() {
        return {
            filterValue: '',
            totalPages: 1,
            currentPage: 1,
            itemsPerPage: 50,
            isDataLoading: false,
            selected: 'Month',
            tableItems: [],
            tableFields: [
                { key: 'id', label: 'ID' },
                { key: 'avatarUrl_1X', label: '' },
                { key: 'name', label: 'Adı' },
                { key: 'registeredAt', label: 'Kayıt Tarihi', _classes: 'text-center' },
                { key: 'eMail', label: 'Mail', _classes: 'text-center' },
            ],
        }
    },
    methods: {
        fallback(event) {
            event.target.src = 'https://png.pngitem.com/pimgs/s/522-5220445_anonymous-profile-grey-person-sticker-glitch-empty-profile.png'
        },
        color(value) {
            let $color
            if (value <= 25) {
                $color = 'info'
            } else if (value > 25 && value <= 50) {
                $color = 'success'
            } else if (value > 50 && value <= 75) {
                $color = 'warning'
            } else if (value > 75 && value <= 100) {
                $color = 'danger'
            }
            return $color
        },
        async loadStudents() {
            this.isDataLoading = true
            const id = parseInt(this.$route.params['id'])
            const result = await ReferralAdministration.listReferralStudents(this.currentPage, this.itemsPerPage, this.filterValue, id)
            console.log(result)
            this.tableItems = result.data.referralStudentList

            this.totalPages = result.data.pagination.totalPages
            this.currentPage = result.data.pagination.currentPage
            this.itemsPerPage = result.data.pagination.recordsPerPage
            
            this.isDataLoading = false
        },
    },
}
</script>
